import { Game, Team, User } from '@freddy/models';
import { OrganizationAndTenant } from '../models/organization';
import { Locale } from 'locale-enum';

export class RedirectToAdminAction {
  static readonly type = '[Connect] Redirect to the admin console of the game';
  constructor(
    public readonly game: Game,
    public readonly organizationSlug: string,
  ) {}
}

export class FetchGameAction {
  static readonly type = '[Connect] Join game';
  constructor(public readonly code: string) {}
}

export class RejoinGameAction {
  static readonly type = '[Connect] Rejoin game';
  constructor() {}
}

export class JoinGameWrongCodeAction {
  static readonly type = '[Connect] No games are associated to this code';
  constructor() {}
}

export class DisconnectPlayerAction {
  static readonly type = '[Connect] Disconnect player';
  constructor() {}
}

export class GameIsFinishedAction {
  static readonly type = '[Connect] Game is finished';
  constructor() {}
}

export class LoadGameAssetsAction {
  static readonly type = '[Connect] Game will loading assets';
  constructor(public readonly game: Game) {}
}

export class LoadGameAssetsSuccessAction {
  static readonly type = '[Connect] Game assets loaded';
  constructor() {}
}

export class AuthenticateUserAction {
  static readonly type = '[Connect] Authenticate user';
  constructor(public readonly organizationSlug: string) {}
}

export class SetTenantAndOrganizationAction {
  static readonly type = '[Connect] Set tenant and organization';
  constructor(public readonly organization: OrganizationAndTenant) {}
}

export class SetUserDataAction {
  static readonly type = '[Connect] Set user data';
  constructor(public readonly user: User) {}
}

export class ListenCommandsActions {
  static readonly type = '[Connect] Will Listen to the commands collection';
  constructor(public readonly team: Team) {}
}

export class ListenRouteActions {
  static readonly type = '[Connect] Will Listen to the angular route changes';
}

export class SelectLanguageAction {
  static readonly type = '[Connect] Select language';
  constructor(public readonly lang: Locale) {}
}
