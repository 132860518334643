// network-monitor.service.ts
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NetworkMonitorService {
  private dataUsed: number = 0;

  constructor(private ngZone: NgZone) {
    this.initPerformanceMonitoring();
  }

  /**
   * Initializes PerformanceObserver to monitor resource loading.
   */
  private initPerformanceMonitoring() {
    if (window.PerformanceObserver) {
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        entries.forEach((entry) => {
          if (entry instanceof PerformanceResourceTiming) {
            this.handleResourceEntry(entry);
          }
        });
      });

      observer.observe({ type: 'resource', buffered: true });
    } else {
      console.warn('PerformanceObserver is not supported in this browser.');
    }
  }

  /**
   * Handles individual resource entries.
   * @param entry PerformanceResourceTiming entry.
   */
  private handleResourceEntry(entry: PerformanceResourceTiming) {
    const resourceSize = entry.encodedBodySize;
    this.dataUsed += resourceSize;

    // Log resource details
    this.ngZone.run(() => {
      console.log(`Resource Loaded: ${entry.name}`);
      console.log(`Size: ${resourceSize} bytes`);
      console.log(`Load Time: ${entry.duration} ms`);
    });
  }

  /**
   * Returns the total data used in bytes.
   */
  public getDataUsed(): number {
    return this.dataUsed;
  }

  /**
   * Resets the data usage counter.
   */
  public resetDataUsed() {
    this.dataUsed = 0;
  }
}
