import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { BehaviorSubject, Observable } from 'rxjs';
import { queue } from 'async';
import { FirebaseStorageService } from './firebase-storage.service';
import { FileUtils } from '../utils/file.utils';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ImageCacheService {
  constructor(
    private storageService: FirebaseStorageService,
    private readonly platform: Platform,
  ) {}

  subject = new BehaviorSubject(0);
  private concurrencyLimit = 10; // Maximum number of concurrent downloads
  private queue = queue<string>((task, callback) => {
    this.getImage(task).then((path) => {
      callback();
    });
  }, this.concurrencyLimit);

  cacheImages(firebasePaths: string[]): Observable<number> {
    this.subject = new BehaviorSubject(0);
    firebasePaths.forEach((path) => {
      this.queue.push(path, (err, result) => {
        this.setProgress(
          this.queue.length() + this.queue.running(),
          firebasePaths.length,
        );
      });
    });
    this.setProgress(
      this.queue.length() + this.queue.running(),
      firebasePaths.length,
    );
    this.queue.drain().then(() => {
      this.subject.complete();
    });
    return this.subject;
  }

  /**
   *
   * @param firebasePath
   * @private
   */

  async getImage(firebasePath: string): Promise<string> {
    const filePath = this.getFilePath(firebasePath);

    try {
      // Check if the image is already cached
      const fileExists = await Filesystem.stat({
        path: filePath,
        directory: Directory.Cache,
      });

      const readFile = await Filesystem.readFile({
        path: fileExists.uri,
      });

      console.log(
        'Image already exists in the cache, return the file URL',
        fileExists.uri,
      );
      return `data:image/jpeg;base64,${readFile.data}`;
    } catch (error) {
      try {
        console.log('Image not found in the cache, download and cache it');
        const file = await this.storageService.getFile(firebasePath);
        if (file && file.size > 0) {
          const base64Data = await FileUtils.convertFileToBase64(file);
          await Filesystem.writeFile({
            path: filePath,
            data: base64Data,
            directory: Directory.Cache,
          });
        }

        if (this.platform.is('hybrid')) {
          return filePath;
        } else {
          return await this.storageService.getDownloadUrl(firebasePath);
        }
        // Return the cached file URL
      } catch (error) {
        throw new Error('Failed to cache image');
      }
    }
  }

  private setProgress(inProgress: number, total: number): void {
    this.subject.next(100 - Math.round((inProgress / total) * 100));
  }

  private getFilePath(firebasePath: string): string {
    // Get the file path based on the platform
    return `${FileUtils.getFileName(firebasePath)}`;
  }
}
