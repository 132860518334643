export const environment = {
  name: 'staging',
  version: 'v1-232-0',
  sentry: {
    dsn: 'https://8e8579440b241f7beca527816cf959a3@o4506207716114432.ingest.sentry.io/4506581323153408',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    sendClientReports: true,
    showDialog: true,
  },
  production: false,
  admin: {
    url: 'https://staging-admin.freddy.ovh',
  },
  firebase: {
    region: 'europe-west1',
    projectId: 'freddy-staging-f98b6',
    appId: '1:421881520656:web:1e3bfabb2b1d13e4426095',
    storageBucket: 'freddy-staging-f98b6.appspot.com',
    databaseURL:
      'https://freddy-staging-f98b6-default-rtdb.europe-west1.firebasedatabase.app/',
    apiKey: 'AIzaSyCjCK5q3hrftECRc7eET4-HWXxN7Ipu4Gw',
    authDomain: 'staging.freddy.ovh',
    messagingSenderId: '421881520656',
    measurementId: 'G-4ZXXGEBJMV',
    recaptcha: '6LffzlcpAAAAAKHDLbIvIhEEhD9hc8v6VNBwDMBf',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNsZTRvdzlwajA1N28zb3BiaTB5aXB2aTAifQ.92uATyIv0TO1Xx93i14xhg',
    geocoder: {
      countriesActivated: 'fr,nl,be',
    },
    style: 'mapbox://styles/bokzor/clzzip5s8005v01phbvfvddax',
    preload: {
      enabled: true,
    },
    defaultZoom: 16,
    minZoom: 13,
    maxZoom: 19,
  },
};
