<ng-container *ngIf="leaderboard$ | async as leaderboard">
  <div class="background">
    <div class="container">
      <div class="leaders">
        <h2>Freddy</h2>
        <ul>
          <li *ngFor="let i of [0, 1, 2]">
            <div
              class="lead-cats"
              *ngIf="leaderboard.teams[i]; else emptyPodium"
            >
              <img
                class="lead-cats__photo"
                [ngSrc]="leaderboard.teams[i].photoUrl"
                [width]="100"
                [height]="100"
                [alt]="leaderboard.teams[i].name"
                [class.active]="leaderboard.teams[i].name === newLeader"
              />
              <div class="podium" [class]="'pod-' + (i + 1)">
                <div
                  class="ranking-lead"
                  [style.backgroundColor]="getCatRankColor(i)"
                >
                  {{ catRank[i].r + 1 }}
                </div>
                <h4>{{ leaderboard.teams[i].name }}</h4>
                <p>{{ leaderboard.teams[i].points }} points</p>
              </div>
            </div>
            <ng-template #emptyPodium>
              <div class="lead-cats">
                <div class="podium" [class]="'pod-' + (i + 1)">
                  <div
                    class="ranking-lead"
                    [style.backgroundColor]="getCatRankColor(i)"
                  >
                    {{ catRank[i].r + 1 }}
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="board">
        <ul>
          <li
            *ngFor="let team of leaderboard.teams; let i = index"
            class="cat-item"
          >
            <div class="cat-item__photo">
              <div class="ranking" [style.backgroundColor]="getCatRankColor(i)">
                {{ i + 1 }}
              </div>
              <img
                [width]="50"
                [height]="50"
                [ngSrc]="team.photoUrl"
                [alt]="team.name"
              />
            </div>
            <div class="cat-item__info">
              <h4>{{ team.name }}</h4>
            </div>
            <div class="cat-item__points">
              <p>{{ team.points }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
