import { Injectable } from '@angular/core';
import {
  concatWith,
  Observable,
  ReplaySubject,
  retry,
  throwError,
  timer,
} from 'rxjs';
import { auditTime, catchError, shareReplay, take } from 'rxjs/operators';
import { Geolocation, Position } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  private isListening = false;
  private watchId: string | null = null;
  private positionSubject = new ReplaySubject<Position>(1);

  // Configuration options
  private readonly MAX_RETRIES = 3;
  private readonly RETRY_DELAY = 5000; // 5 seconds
  private readonly POSITION_TIMEOUT = 15000; // 15 seconds

  /**
   * Returns an Observable of the current position of the device.
   * @param durationMs The duration in milliseconds to cache the position.
   */
  getPosition(durationMs = 20000): Observable<Position> {
    return this.positionSubject.pipe(
      take(1), // Emit the first value immediately
      concatWith(
        this.positionSubject.pipe(
          auditTime(durationMs), // Emit subsequent values at most every 20 seconds
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }), // Cache the latest value
      retry({
        count: this.MAX_RETRIES,
        delay: (_, attempt) => timer(this.RETRY_DELAY * (attempt + 1)),
        resetOnSuccess: true,
      }),
      catchError((error) =>
        throwError(() => new Error(`Failed to get position: ${error}`)),
      ),
    );
  }

  /**
   * Starts tracking the device's position.
   * @returns A promise that resolves to the watch ID if tracking started successfully, or rejects with an error.
   */
  async startTrackingPosition(): Promise<string> {
    if (this.isListening) {
      return Promise.resolve(this.watchId!);
    }

    try {
      const permissionStatus = await this.requestPermission();
      if (permissionStatus !== 'granted') {
        throw new Error('Location permission not granted');
      }

      this.isListening = true;
      this.watchId = await Geolocation.watchPosition(
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: this.POSITION_TIMEOUT,
        },
        (position, error) => {
          if (error) {
            this.positionSubject.error(
              new Error(`Geolocation error: ${error.message}`),
            );
          } else if (position) {
            this.positionSubject.next(position);
          }
        },
      );

      return this.watchId;
    } catch (error) {
      this.isListening = false;
      throw new Error(
        `Failed to start tracking position: ${error instanceof Error ? error.message : String(error)}`,
      );
    }
  }

  /**
   * Stops tracking the device's position.
   */
  async stopTrackingPosition(): Promise<void> {
    if (this.watchId) {
      await Geolocation.clearWatch({ id: this.watchId });
      this.watchId = null;
      this.isListening = false;
    }
  }

  /**
   * Requests location permission from the user.
   * @returns A promise that resolves to the permission status.
   */
  async requestPermission(): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      return (await Geolocation.requestPermissions()).location;
    } else {
      return Promise.resolve('granted');
    }
  }
}
