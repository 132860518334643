import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Locale } from 'locale-enum';
import { SoundService } from './sound.service';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Observable,
} from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SoundConfig } from '../../shared/models/Sound';
import { Select, Store } from '@ngxs/store';
import { ConnectState } from '../stores/connect.store';

interface Theme {
  id: string;
  sounds: Partial<Record<Locale | 'default', string>>;
  stylesheet?: string;
}

interface ThemeConfig {
  themes: Theme[];
  defaultThemeId: string;
}

export const THEME_TOKEN = new InjectionToken<ThemeConfig>('ThemeConfig');

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  @Select(ConnectState.currentLang) currentLang$!: Observable<Locale>;

  private currentThemeSubject = new BehaviorSubject<Theme | undefined>(
    undefined,
  );
  private renderer: Renderer2;

  constructor(
    @Optional() @Inject(THEME_TOKEN) private themeConfig: ThemeConfig,
    @Inject(DOCUMENT) private document: Document,
    private soundService: SoundService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private store: Store,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.initializeTheme();
    this.handleThemeChanges();
    this.handleLanguageChanges();
  }

  private initializeTheme(): void {
    const defaultTheme = this.getDefaultTheme();
    const urlParams = new URLSearchParams(window.location.search);
    const initialThemeId = urlParams.get('theme');
    const initialTheme = initialThemeId
      ? this.getThemeById(initialThemeId)
      : defaultTheme;
    if (!initialTheme) {
      throw new Error(`Theme with id ${initialThemeId} not found`);
    }
    this.setCurrentTheme(initialTheme);
  }

  private handleThemeChanges(): void {
    this.route.queryParams
      .pipe(
        filter((params) => 'theme' in params),
        distinctUntilChanged((prev, curr) => prev['theme'] === curr['theme']),
      )
      .subscribe(this.applyThemeFromParams.bind(this));
  }

  private handleLanguageChanges(): void {
    this.currentLang$.subscribe(() => {
      const theme = this.currentThemeSubject.value;
      if (theme) this.loadSoundForCurrentLang(theme);
    });
  }

  private applyThemeFromParams(params: Params): void {
    const currentTheme = this.currentThemeSubject.value;
    const queryThemeId = params['theme'];
    const defaultThemeId = this.themeConfig.defaultThemeId;

    if (!queryThemeId && currentTheme?.id === defaultThemeId) return;

    const themeToApply =
      this.getThemeById(queryThemeId || defaultThemeId) ||
      this.getDefaultTheme();

    if (themeToApply && themeToApply.id !== currentTheme?.id) {
      this.setCurrentTheme(themeToApply);
    }
  }

  private setCurrentTheme(theme: Theme): void {
    this.currentThemeSubject.next(theme);
    this.loadSoundForCurrentLang(theme);
    this.loadStyleForTheme(theme);
  }

  private getDefaultTheme(): Theme {
    return this.getThemeById(this.themeConfig.defaultThemeId) as Theme;
  }

  private getThemeById(themeId: string): Theme | undefined {
    return this.themeConfig?.themes.find((theme) => theme.id === themeId);
  }

  private loadSoundForCurrentLang(theme: Theme): void {
    const currentLang = this.store.selectSnapshot(ConnectState.currentLang);
    const soundPath = theme.sounds[currentLang] || theme.sounds['default'];

    if (soundPath) {
      this.loadSoundConfig(soundPath);
    } else {
      console.error('No sound configuration found for language:', currentLang);
    }
  }

  private loadSoundConfig(path: string): void {
    this.http.get<SoundConfig>(path).subscribe({
      next: (soundConfig) => this.soundService.loadSound(soundConfig),
      error: (error) =>
        console.error('Error loading sound configuration:', error),
    });
  }

  private loadStyleForTheme(theme: Theme): void {
    if (theme.stylesheet) {
      this.loadStyle(theme.stylesheet);
    }
  }

  private loadStyle(styleName: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById(
      'client-theme',
    ) as HTMLLinkElement;

    if (!themeLink) {
      themeLink = this.renderer.createElement('link');
      this.renderer.setProperty(themeLink, 'id', 'client-theme');
      this.renderer.setProperty(themeLink, 'rel', 'stylesheet');
      this.renderer.appendChild(head, themeLink);
    }

    this.renderer.setProperty(themeLink, 'href', `${styleName}.css`);
  }
}
