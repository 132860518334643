import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { debounceTime, Observable, Subscription } from 'rxjs';
import { Command, Team, TeamState } from '@freddy/models';
import { tap } from 'rxjs/operators';
import { CommandRepository } from '../repository/command.repository';
import { PathParams } from '../../../../../common/src/lib';

@Injectable({
  providedIn: 'root',
})
export class CommandService implements OnDestroy {
  constructor(
    private readonly store: Store,
    private readonly commandRepository: CommandRepository,
  ) {
    this.subscribeToTeamChanges();
  }

  @Select(InGameState.myTeam)
  private myTeam$!: Observable<Team>;
  private commandQueue: Command[] = [];
  private isProcessingCommand = false;
  private teamSubscription: Subscription | null = null;
  private playerStatus?: TeamState;

  subscribeToTeamChanges() {
    this.teamSubscription = this.myTeam$
      .pipe(
        tap((team) => {
          // Update player status based on the team's currentPath
          if (team) {
            this.playerStatus = team.currentState;
            this.tryProcessingNextCommand(); // Try to process commands if the player is ready
          }
        }),
      )
      .subscribe();
  }

  sendCommand(command: Command, pathParams?: PathParams): Observable<void> {
    return this.commandRepository.sendCommand(command, pathParams);
  }

  tryProcessingNextCommand() {
    if (
      this.playerStatus === 'AVAILABLE' &&
      !this.isProcessingCommand &&
      this.commandQueue.length > 0
    ) {
      this.isProcessingCommand = true;
      const command = this.commandQueue.shift();
      if (command)
        this.executeCommand(command).then(() => {
          this.isProcessingCommand = false;
          this.tryProcessingNextCommand(); // Try the next command after finishing
        });
    }
  }

  async executeCommand(command: Command) {
    return new Promise((resolve) => {
      // Simulate command execution
      console.log(`Executing command: ${command.action}`);
      this.commandRepository.markCommandAsExecuted(command.uid);
      this.store.dispatch(command.action).subscribe(() => {
        resolve(true);
      });
    });
  }

  listenForCommands(teamUid: string) {
    if (teamUid) {
      return this.commandRepository.listenForCommands(teamUid).pipe(
        debounceTime(3000),
        tap((commands) => {
          commands.forEach((command) => {
            // Instead of executing immediately, push to queue
            this.commandQueue.push(command);
          });
          this.tryProcessingNextCommand(); // Attempt to process the next command
        }),
      );
    }
    return;
  }

  // Make sure to clean up the subscription to prevent memory leaks
  ngOnDestroy() {
    if (this.teamSubscription) {
      this.teamSubscription.unsubscribe();
    }
  }
}
