import { Injectable } from '@angular/core';
import { CommonRepositoryAbstract, PathParams } from '@freddy/common';
import { InGameState } from '../stores/in-game.store';
import { Team } from '@freddy/models';
import { Firestore, QueryConstraint, where } from '@angular/fire/firestore';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamRepository extends CommonRepositoryAbstract<Team> {
  constructor(
    firestore: Firestore,
    private readonly store: Store,
  ) {
    super(firestore);
  }

  getTeamByUid(teamUid: string): Observable<Team | undefined> {
    const queryConstraints: QueryConstraint[] = [where('uid', '==', teamUid)];

    return this.getCollections(undefined, queryConstraints).pipe(
      map((teams) => (teams.length > 0 ? teams[0] : undefined)),
    );
  }

  getTeamByUserUid(userUid: string): Observable<Team | undefined> {
    const queryConstraints: QueryConstraint[] = [
      where('userUid', '==', userUid),
    ];

    return this.getCollections(undefined, queryConstraints).pipe(
      map((teams) => (teams.length > 0 ? teams[0] : undefined)),
    );
  }

  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/teams`;
  }
}
