import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { CustomPreloadingStrategyService } from './core/preload/preload.strategy';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';

const routes: Routes = [
  {
    path: '',
    data: {
      preload: true,
    },
    loadChildren: () =>
      import('./connect/connect.module').then((m) => m.ConnectModule),
  },
  {
    path: 'game',
    data: {
      preload: true,
      loadAfterSeconds: 10,
    },
    loadChildren: () => import('./game/game.module').then((m) => m.GameModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: ':organizationId/leaderboard/:gameId',
    component: LeaderboardComponent,
  },
];

@NgModule({
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
