import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  collection,
  collectionData,
  Firestore,
  limit,
  query,
} from '@angular/fire/firestore';
import { SharedModule } from '../shared/shared.module';
import confetti from 'canvas-confetti';

interface Leaderboard {
  gameName: string;
  teams: Team[];
}

interface Team {
  name: string;
  photoUrl: string;
  points: number;
}

@Component({
  selector: 'tmu-leaderboard',
  standalone: true,
  imports: [AsyncPipe, SharedModule],
  templateUrl: './leaderboard.component.html',
  styleUrl: './leaderboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaderboardComponent implements OnInit {
  leaderboard$: Observable<Leaderboard> | undefined;

  catRank = [
    { r: 1, c: '#bbbbbb' },
    { r: 0, c: '#d6cd1e' },
    { r: 2, c: '#d6a21e' },
  ];

  newLeader = '';

  constructor(
    private route: ActivatedRoute,
    private firestore: Firestore,
  ) {}

  ngOnInit() {
    this.showConfetti();
  }

  showConfetti() {
    const end = Date.now() + 15 * 1000;
    const colors = ['#bb0000', '#ffffff'];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
    this.leaderboard$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const gameId = params.get('gameId');
        const organizationId = params.get('organizationId');

        if (organizationId && gameId) {
          const leaderboardPath = `organizations/${organizationId}/games/${gameId}/leaderboards`;
          const leaderboardCollection = collection(
            this.firestore,
            leaderboardPath,
          );
          const leaderboardQuery = query(leaderboardCollection, limit(1));

          return collectionData(leaderboardQuery).pipe(
            map(
              (leaderboards: any[]) =>
                leaderboards[0] || { gameName: '', teams: [] },
            ),
            map(this.processLeaderboard),
          );
        } else {
          return of({ gameName: '', teams: [] });
        }
      }),
    );
  }

  private processLeaderboard(leaderboard: Leaderboard): Leaderboard {
    const sortedTeams = [...leaderboard.teams].sort(
      (a, b) => b.points - a.points,
    );
    return { ...leaderboard, teams: sortedTeams };
  }

  getCatRankColor(index: number): string {
    return this.catRank[index]?.c || '#bbbbbb';
  }

  getTopThree(teams: Team[]): Team[] {
    const topThree = teams.slice(0, 3);
    return [topThree[2], topThree[0], topThree[1]].filter(Boolean);
  }
}
