import {
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
} from 'firebase/firestore';

import { from, Observable } from 'rxjs';
import { AtLeastWithUid, WithUid } from '../interfaces/utility/type';
import { BaseFirebaseModel } from '../interfaces/firebase-model/base.firebase.model';
import { map } from 'rxjs/operators';
import { FirebaseUtils } from '../utils/firebase.utils';
import {
  collection,
  collectionData,
  docData,
  endBefore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  QueryConstraint,
  startAfter,
} from '@angular/fire/firestore';
import { PaginatedResult } from '../interfaces';

export interface QueryParam<T = DocumentData> {
  startAfter?: QueryDocumentSnapshot<T>;
  endBefore?: QueryDocumentSnapshot<T>;
  currentPage?: number;
  limit?: number;
  where?: QueryConstraint[];
  orderBy: string;
}

export interface RepositoryOptions {
  pageSize: number;
}

export interface PathParams {
  [key: string]: string;
}

export abstract class CommonRepositoryAbstract<T extends BaseFirebaseModel> {
  static DEFAULT_LIMIT = 20;

  constructor(protected readonly firestore: Firestore) {}

  create(documentData: WithUid<T>, pathParams?: PathParams): Observable<T> {
    const path = `${this.generateDocPath(pathParams)}/${documentData.uid}`;
    console.log('Trying to create doc', path);
    const docRef = doc(this.firestore, path);
    return from(
      setDoc(docRef, {
        ...this.filterUndefinedValues(documentData),
        updatedAt: new Date(),
        createdAt: new Date(),
      }),
    ).pipe(map(() => documentData));
  }

  update(
    documentData: AtLeastWithUid<T>,
    pathParams?: PathParams,
  ): Observable<void> {
    const path = `${this.generateDocPath(pathParams)}/${documentData.uid}`;
    console.log('Trying to update or create doc', path);
    const docRef = doc(this.firestore, path);
    return from(
      setDoc(
        docRef,
        {
          ...this.filterUndefinedValues(documentData),
          updatedAt: new Date(),
        },
        { merge: true },
      ),
    );
  }

  delete(
    documentData: AtLeastWithUid<T>,
    pathParams?: PathParams,
  ): Observable<void> {
    const path = `${this.generateDocPath(pathParams)}/${documentData.uid}`;
    console.log('Trying to delete doc', path);
    const docRef = doc(this.firestore, path);
    return from(deleteDoc(docRef));
  }

  set(documentData: WithUid<T>, pathParams?: PathParams): Observable<string> {
    const path = `${this.generateDocPath(pathParams)}/${documentData.uid}`;
    console.log('Trying to set doc', path);
    const docRef = doc(this.firestore, path);
    return from(setDoc(docRef, this.filterUndefinedValues(documentData))).pipe(
      map(() => path),
    );
  }

  get(uid: string, pathParams?: PathParams): Observable<T> {
    const path = `${this.generateDocPath(pathParams)}/${uid}`;
    console.log('Trying to get doc', path);
    const docRef = doc(this.firestore, path);
    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          return FirebaseUtils.convertDate(docSnapshot.data() as T);
        }
        throw new Error(`Doc not found: ${path}`);
      }),
    );
  }

  getDocChanges(uid: string, pathParams?: PathParams): Observable<T> {
    const path = `${this.generateDocPath(pathParams)}/${uid}`;
    console.log('Trying to get doc changes', path);
    const docRef = doc(this.firestore, path);
    return docData(docRef, { idField: 'uid' }).pipe(
      map((data) => FirebaseUtils.convertDate(data as T)),
    );
  }

  add(documentData: T, pathParams?: PathParams): Observable<void> {
    const collectionPath = this.generateDocPath(pathParams);
    console.log('Trying to add doc', collectionPath);
    const collectionRef = collection(this.firestore, collectionPath);
    // Assuming documentData.uid is used as the document ID. Adjust as necessary.
    const docRef = doc(collectionRef, documentData.uid);
    return from(setDoc(docRef, this.filterUndefinedValues(documentData)));
  }

  getCollectionsChanges(
    pathParams?: PathParams,
    queryConstraints: QueryConstraint[] = [],
  ): Observable<T[]> {
    const path = this.generateDocPath(pathParams);
    console.log('Trying to get collection changes', path);
    const collectionRef = collection(this.firestore, path);
    const q = query(collectionRef, ...queryConstraints);
    return collectionData(q, { idField: 'uid' }).pipe(
      map((docs) => docs.map((doc) => FirebaseUtils.convertDate(doc as T))),
    );
  }

  getCollections(
    pathParams?: PathParams,
    queryConstraints: QueryConstraint[] = [],
  ): Observable<T[]> {
    const path = this.generateDocPath(pathParams);
    const collectionRef = collection(this.firestore, path);
    const q = query(collectionRef, ...queryConstraints);

    return from(getDocs(q)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((doc) =>
          FirebaseUtils.convertDate(doc.data() as T),
        ),
      ),
    );
  }

  list(
    queryParams: QueryParam,
    pathParams?: PathParams,
  ): Observable<PaginatedResult<T>> {
    const path = this.generateDocPath(pathParams);
    const constraints: QueryConstraint[] = [...(queryParams.where ?? [])];
    const pageSize = this.getRepositoryOptions().pageSize;

    if (queryParams.orderBy) {
      constraints.push(orderBy(queryParams.orderBy, 'desc'));
    }

    if (queryParams.startAfter) {
      constraints.push(startAfter(queryParams.startAfter));
    } else if (queryParams.endBefore) {
      constraints.push(endBefore(queryParams.endBefore));
    }

    constraints.push(
      queryParams.endBefore ? limitToLast(pageSize) : limit(pageSize),
    );

    const firestoreQuery = query(
      collection(this.firestore, path),
      ...constraints,
    );

    return from(getDocs(firestoreQuery)).pipe(
      map((querySnapshot: QuerySnapshot) =>
        this.mapQuerySnapshotToPaginatedResult(
          querySnapshot as QuerySnapshot<T>,
          queryParams,
        ),
      ),
    );
  }

  public getRepositoryOptions(): RepositoryOptions {
    return {
      pageSize: CommonRepositoryAbstract.DEFAULT_LIMIT,
    };
  }

  protected abstract getDocPath(): string;

  protected generateDocPath(pathParams?: PathParams): string {
    let docPath = this.getDocPath();
    if (pathParams) {
      Object.keys(pathParams).forEach((key) => {
        if (!pathParams[key]) {
          throw new Error(`Missing path params: ${pathParams[key]}`);
        }
        docPath = docPath.replace(`/:${key}/`, `/${pathParams[key]}/`);
      });
    }
    if (docPath.includes('/:')) {
      throw new Error(`Missing path params: ${docPath}`);
    }
    return docPath;
  }

  private mapQuerySnapshotToPaginatedResult(
    querySnapshot: QuerySnapshot<T>,
    queryParams: QueryParam,
  ): PaginatedResult<T> {
    const currentPage = queryParams.currentPage ?? 0;
    const items = querySnapshot.docs.map((doc) =>
      FirebaseUtils.convertDate(doc.data()),
    );
    const pageSize = this.getRepositoryOptions().pageSize;
    const hasMoreItems = querySnapshot.size === pageSize;

    let newPage = currentPage;
    if (queryParams.startAfter || (currentPage === 0 && hasMoreItems)) {
      newPage = currentPage + 1;
    } else if (queryParams.endBefore && hasMoreItems) {
      newPage = Math.max(currentPage - 1, 1);
    }

    const lastItem = querySnapshot.docs[querySnapshot.size - 1];
    const firstItem = querySnapshot.docs[0];

    return {
      items: items as T[],
      nextDisabled: !hasMoreItems,
      prevDisabled: newPage <= 1,
      currentPage: newPage,
      lastItem,
      firstItem,
    };
  }

  protected filterUndefinedValues(data: Record<string, any>) {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined),
    );
  }
}
